import api from "@/request/api";

export default {
    all() {
        return api.get('stock/list');
    },
    find(id) {
        return api.get('stock/info', {id:id});
    },
    history(id) {
        return api.get('stock/history', {id: id});
    },
    list() {
        return api.get('stock/list')
    }
}