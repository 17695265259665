<template>
  <div id="finance-stock">
    <h4>购票记录<b-button v-b-modal.modal-1 class="float-right" size="sm" variant="primary">添加购票记录</b-button></h4>
    <div class="my-3">
      <b-modal id="modal-1" title="添加购票记录" @ok="onFormSubmit" :ok-title="'保存'" :cancel-title="'取消'">
        <form action="" @submit="onFormSubmit">
          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label">渠道</label>
            <div class="col-sm-10">
              <select class="form-control">
                <option selected>请选择渠道</option>
                <option value="1">中国移动</option>
                <option value="2">中国联通</option>
                <option value="2">中国电信</option>
              </select>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label">流水号</label>
            <div class="col-sm-10">
              <input type="text" name="orderSn" class="form-control" v-model="form.orderSn">
            </div>
          </div>

          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label">类型</label>
            <div class="col-sm-10">
              <select v-model="form.type" class="form-control">
                <option value="-1" selected disabled>请选择游戏</option>
                <option value="1">橙意满满</option>
                <option value="2">庚子鼠</option>
              </select>
            </div>
          </div>
          <div class="mb-3 row">
            <label for="inputIncome" class="col-sm-2 col-form-label">数量（张）</label>
            <div class="col-sm-10">
              <input type="number" name="quantity" class="form-control" v-model="form.quantity">
            </div>
          </div>

          <div class="mb-3 row">
            <label for="inputIncome" class="col-sm-2 col-form-label">金额</label>
            <div class="col-sm-10">
              <input type="text" name="text" class="form-control" id="inputIncome" v-model="form.amount">
            </div>
          </div>


          <div class="mb-3 row">
            <label for="inputBody" class="col-sm-2 col-form-label">备注</label>
            <div class="col-sm-10">
              <textarea class="form-control" name="body" id="inputBody" v-model="form.body"></textarea>
            </div>
          </div>
        </form>
      </b-modal>
      <Loading :loading="loading"/>
      <b-table :items="list" :fields="fields" hover striped head-variant="light"/>
    </div>

  </div>
</template>
<script>
// import Finance from "@/model/Finance"
import Stock from "@/model/Stock"
// import Card from "@/components/Card"
import Loading from "@/components/Loading"

export default {
  components:{
    Loading,
  },
  data() {
    return {
      fields:[
        {key:'orderSn',label:'购票单号'},
        {key:'created_at', label:'提交时间'},
        {key:'amount',label:'金额'},
        {key:'quantity',label:'数量（张）'},
        {key:'status', label:'状态'},
        {key:'channel', label:'渠道'},
        {key:'type', label:'游戏'},
      ],
      list: [],
      form: {
        orderSn: '',
        channel: -1,
        type: -1,
        quantity: '',
        amount: '',
        remark: '',
      },
      keywords: {
        start: '',
        end: '',
        type: -1,
        channel: -1,
        orderSn: ''
      },
      loading: true
    }
  },

  mounted() {
    this.init()
  },
  methods: {
    init() {
      Stock.list(this.keywords).then(response => {
        if(response.data.code === 0) {
          this.list = response.data.data;
        }
        this.loading = false
      })
    },
    onFilterSubmit(e) {
      e.preventDefault();
      this.loading = true;
      setTimeout(() => {this.loading = false}, 500)
      this.init();
    },
    onFormSubmit(e) {
      e.preventDefault();
      this.loading = true;
      setTimeout(() => {this.loading = false}, 500)
      this.init();
    }
  }
}
</script>