<template>
  <div class="loading text-center" v-if="loading">
    <div class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>
<script>
export default {
  name:'Loading',
  props: {
    loading: Boolean
  }
}
</script>